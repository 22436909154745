import React from "react";
import "./Footer.scss";

import logo from "../../assets/images/AIRCONIQ-white.png"
import { Link } from 'react-router-dom';

// import GoogleMaps from '../../components/GoogleMaps/GoogleMaps';

const Footer = () => {
  return (
    <>
    <div className="px-2 sm:px-6 lg:px-8 bg-black md:flex md:justify-between">
      <div className="text-white flex md:w-3/6">
        <img className="w-40 h-40" src={logo} />
        <div className="ml-2 py-4">
          <h4 className="md:w-60 text-white text-lg font-semibold">Airconiq Airconditioning Spare Parts Trading - Sole Proprietorship L.L.C</h4>
          <p className="mt-2">Al Salam Street<br />
          Behind KM Trading<br />
          Abu Dhabi - UAE</p>
          <p>+971 2 675 6799</p>
        </div>
      </div>
      {/* <div className="text-white flex md:w-3/6">
        <div>
          <GoogleMaps />
        </div>
      </div> */}
      {/* <div className="text-white">ffff</div> */}
    </div>
      {/* <div class="row primary">
        <div class="column about">
          <h3>Address</h3>
          <p>
            <i class="fa fa-map-marker" aria-hidden="true"></i> Airconiq Airconditioning Spare Parts Trading - Sole Proprietorship L.L.C
          </p>
          <h6>
            Al Salam Street<br />
            Behind KM Trading<br />
            Abu Dhabi - UAE
          </h6>
          <div class="social">
            <i class="fa fa-facebook-square"></i>
            <i class="fa fa-twitter-square"></i>
            <i class="fa fa-linkedin-square"></i>
            <i class="fa fa-instagram"></i>
          </div>
        </div>

        <div class="column link">
          <h3>Links</h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#team">Team</a>
            </li>
            <li>
              <a href="#blogs">Blogs</a>
            </li>
            <li>
              <a href="#support">Support</a>
            </li>
          </ul>
        </div>
      </div> */}
      <div class="flex justify-between row copyright">
        <div class="footer-menu">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <p className="mt-2">Copyright &copy; 2023</p>
      </div>
    </>
  );
};

export default Footer;
