import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Footer from '../../Shared/Footer/Footer';
import Header from '../../Shared/Header/Header';

const Layout = () => {
  return (
    <div>
      <Header />

      <Outlet />

      <Footer />
    </div>
  )
}

export default Layout