import "./Header.scss";

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { NavLink, Link } from 'react-router-dom';

import BrandLogo from "../../assets/images/AIRCONIQ-white.png"

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About', href: '/about' },
  { name: 'Contact', href: '/contact' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open, close }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-20 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                  <img
                    className="block h-16 w-auto lg:hidden"
                    src={BrandLogo}
                    alt="Airconiq"
                  />
                  <img
                    className="hidden h-16 w-auto lg:block"
                    src={BrandLogo}
                    alt="Airconiq"
                  />
                </Link>
                </div>
                {/* <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4 text-gray-300">
                    {navigation.map((item) => (
                        <NavLink
                        to={item.href}
                        aria-current={({ isActive }) => (isActive ? 'page' : undefined)}
                        className={({ isActive }) => (isActive ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : 'text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium')}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div> */}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4 text-gray-300">
                    {navigation.map((item) => (
                        <NavLink
                        to={item.href}
                        aria-current={({ isActive }) => (isActive ? 'page' : undefined)}
                        className={({ isActive }) => (isActive ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : 'text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium')}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <NavLink
                to={item.href}
                className={({ isActive }) => (isActive ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : 'text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium block')}
                onClick={() => close()}
                >
                    {item.name}
                </NavLink>
                // <Disclosure.Button
                //   key={item.name}
                //   as="a"
                //   href={item.href}
                //   className={classNames(
                //     item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                //     'block rounded-md px-3 py-2 text-base font-medium'
                //   )}
                //   aria-current={item.current ? 'page' : undefined}
                // >
                //   {item.name}
                // </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
